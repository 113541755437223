import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { useQuery } from 'react-query';
import carriersQueryKeys from './carriersQueryKeys';

export const useCarriersListMasterQuery = (options = {} as any) => {
    const { authToken } = useAuthSelector();
    const baseQueryKey = carriersQueryKeys.listMaster();
    const queryKey = options.params ? [...baseQueryKey, options.params] : baseQueryKey;
    const query = useQuery(queryKey, async () => sdk.carriers.listMaster({ ...options, authToken }), {});

    return {
        ...query,
        queryKey,
    };
};

export default useCarriersListMasterQuery;
