import { api } from 'app/sdk/utils/trunkingio';

const exports = {
    list: async (accountId, options) => {
        const response = await api.get(`/accounts/${accountId}/endpoints`, options);
        return response.data;
    },
    get: async (accountId, endpointId, options) => {
        const response = await api.get(`/accounts/${accountId}/endpoints/${endpointId}`, options);
        return response.data;
    },
    create: async (accountId, payload, options) => {
        const response = await api.put(`/accounts/${accountId}/endpoints`, payload, options);
        return response.data;
    },
    patch: async (accountId, endpointId, payload, options) => {
        const response = await api.patch(`/accounts/${accountId}/endpoints/${endpointId}`, payload, options);
        return response.data;
    },
    delete: async (accountId, endpointId, options) => {
        const response = await api.delete(`/accounts/${accountId}/endpoints/${endpointId}`, options);
        return response.data;
    },
};

export default exports;
