import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { useQuery } from 'react-query';
import carriersQueryKeys from './carriersQueryKeys';

export const useCarriersListQuery = (options = {} as any) => {
    const { authToken, currentAccountId } = useAuthSelector();
    const accountId = (options.accountId ?? currentAccountId) as string;
    const baseQueryKey = carriersQueryKeys.list(accountId);
    const queryKey = options.params ? [...baseQueryKey, options.params] : baseQueryKey;
    const query = useQuery(queryKey, async () => sdk.carriers.list(accountId, { ...options, authToken }), {});

    return {
        ...query,
        queryKey,
    };
};

export default useCarriersListQuery;
