import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@voiplabscom/design';
import { AddBox as AddBoxIcon, Delete as DeleteIcon, Edit as EditIcon } from '@voiplabscom/design/icons-material';
import _ from 'lodash';
import { useState, useReducer } from 'react';
import { useCustomerEndpointsListQuery } from 'app/hooks/queries/customer_endpoints';
import { useDeleteCustomerEndpoint } from 'app/hooks/mutations/customer_endpoints';
import { Confirm } from 'app/components/common/Confirm';
import { toast } from 'react-toastify';
import { EditCustomerEndpointDialog } from 'app/components/customer_endpoint/EditCustomerEndpointDialog';
import { MainContentCard } from 'app/components/common/MainContentCard';
import { DataTable } from 'app/components/common/DataTable';

function EndpointList() {
    const { data: endpoints, isLoading, error, refetch } = useCustomerEndpointsListQuery();
    const [showConfirm, toggleShowConfirm] = useReducer(show => !show, false);
    const [confirm, setConfirm] = useState({} as any);
    const [showEndpointDialog, toggleShowEndpointDialog] = useReducer(show => !show, false);
    const [endpointDialog, setEndpointDialog] = useState({} as any);
    const deleteCustomerEndpoint = useDeleteCustomerEndpoint();

    const endpointList = _.sortBy(endpoints, ['description']);
    const tableColumns = [
        { key: 'description', header: 'Equipment', sx: { /* width: '1%',  */ whiteSpace: 'nowrap' } },
        {
            key: 'ip',
            header: 'IP Address',
            sx: { /* width: '1%',  */ whiteSpace: 'nowrap' },
            custom: row => {
                return row.ip + ':' + row.port;
            },
        },
        { key: 'protocol', header: 'Protocol', sx: { /* width: '1%',  */ whiteSpace: 'nowrap' }, transform: _.toUpper },
        { key: 'direction', header: 'Direction', sx: { /* width: '1%',  */ whiteSpace: 'nowrap' } },
    ];

    const deleteEndpoint = async endpoint => {
        deleteCustomerEndpoint.mutate(
            { endpointId: endpoint.id },
            {
                onSuccess: () => {
                    toast.success(
                        `Endpoint ${endpoint.ip}:${
                            endpoint.port
                        } (${endpoint.protocol.toUpperCase()}) successfully deleted.`,
                    );
                    refetch();
                },
                onError: error => {
                    //@ts-ignore
                    const errorMessage = error?.response?.data?.message ?? 'Failed to delete Endpoint.';
                    toast.error(errorMessage);
                    refetch();
                },
            },
        );
    };

    const onDelete = endpoint => {
        setConfirm({
            title: 'Do you really want to delete this Endpoint?',
            content: (
                <>
                    {endpoint.name ? <Typography>Name: {endpoint.name}</Typography> : null}
                    {endpoint.description ? <Typography>Description: {endpoint.description}</Typography> : null}
                    <Typography>
                        Address: {endpoint.ip}:{endpoint.port}
                    </Typography>
                    <Typography>Protocol: {endpoint.protocol.toUpperCase()}</Typography>
                </>
            ),
            confirmText: 'Delete',
            confirmColor: 'error',
            confirmVariant: 'text',
            cancelColor: 'primary',
            cancelVariant: 'text',
            onConfirm: () => {
                deleteEndpoint(endpoint);
                toggleShowConfirm();
            },
            onCancel: () => {
                toggleShowConfirm();
            },
        });
        toggleShowConfirm();
    };
    const onEdit = endpoint => {
        setEndpointDialog({
            endpoint,
            onCancel: toggleShowEndpointDialog,
            onComplete: () => {
                toggleShowEndpointDialog();
                refetch();
            },
        });
        toggleShowEndpointDialog();
    };
    const onAdd = () => {
        setEndpointDialog({
            create: true,
            onCancel: toggleShowEndpointDialog,
            onComplete: () => {
                toggleShowEndpointDialog();
                refetch();
            },
        });
        toggleShowEndpointDialog();
    };

    const routingIps = ['23.93.104.5', '100.55.22.33'];
    const routingIpsText =
        routingIps.length > 1 ? routingIps.slice(0, -1).join(', ') + ' and ' + routingIps.slice(-1) : routingIps[0];

    return (
        <Box component="div">
            <MainContentCard
                title="Your Equipment / Endpoints"
                sx={{
                    marginTop: '40px',
                }}
            >
                <Typography component="h6" variant="h6">
                    Configure your equipment.
                </Typography>
                <Typography sx={{ fontSize: '1rem' }}>
                    Register equipment or route traffic to {routingIpsText}
                </Typography>
                <DataTable
                    sx={{ marginTop: '20px' }}
                    rows={endpointList}
                    columns={tableColumns}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onAdd={onAdd}
                    addButtonText="Add New Endpoint"
                    addButtonType="link"
                    isLoading={isLoading}
                    errorMessage={
                        //@ts-ignore
                        error?.message
                    }
                />
            </MainContentCard>
            {showConfirm ? (
                <Confirm
                    title={confirm.title}
                    cancelText={confirm.cancelText}
                    onCancel={confirm.onCancel}
                    confirmText={confirm.confirmText}
                    confirmColor={confirm.confirmColor}
                    confirmVariant={confirm.confirmVariant}
                    cancelColor={confirm.cancelColor}
                    cancelVariant={confirm.cancelVariant}
                    onConfirm={confirm.onConfirm}
                >
                    {confirm.content}
                </Confirm>
            ) : null}
            {showEndpointDialog ? <EditCustomerEndpointDialog {...endpointDialog} /> : null}
        </Box>
    );
}

export default EndpointList;
