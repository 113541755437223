import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth, useAuthSlice } from 'app/data/auth';
import { Button } from '@voiplabscom/design';

function Login() {
    const { actions } = useAuthSlice();
    const dispatch = useDispatch();

    const { loggingIn, loggedIn, error: authError } = useSelector(selectAuth);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = formData => {
        const { username, accountName, password } = formData;
        console.log('formData:', formData);

        dispatch(
            actions.loginWithCredentials({
                username,
                accountName,
                password,
            }),
        );
    };

    const defaultValues = Object.assign(
        {
            username: '',
            password: '',
            account_name: '',
        },
        JSON.parse(process.env.REACT_APP_DEFAULT_LOGIN || '{}'),
    );

    if (loggedIn) {
        return <>Logged in already</>;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'grid',
            }}
        >
            Login Page
            <br />
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>
                    Username:{' '}
                    <input {...register('username', { required: true })} defaultValue={defaultValues.username} />
                    {errors.username && <span style={{ color: 'red' }}> username is required.</span>}
                </label>
                <br />
                <br />
                <label>
                    Account Name:
                    <input {...register('accountName', { required: true })} defaultValue={defaultValues.account_name} />
                    {errors.accountName && <span style={{ color: 'red' }}> account name is required.</span>}
                </label>
                <br />
                <br />
                <label>
                    Password:
                    <input
                        {...register('password', { required: true })}
                        type={'password'}
                        defaultValue={defaultValues.password}
                    />
                    {errors.password && <span style={{ color: 'red' }}> password is required.</span>}
                </label>
                <br />
                <br />
                <div>
                    <Button variant="contained" type="submit">
                        Login Please
                    </Button>
                </div>
                {loggingIn && ' Logging in...'}
                {authError && <span style={{ color: 'red' }}> Error: {authError}</span>}
            </form>
        </div>
    );
}

export default Login;
