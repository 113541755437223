import { api } from 'app/sdk/utils/trunkingio';

const exports = {
    byUser: async (username, accountName, password) => {
        const body = {
            data: {
                username,
                password,
                account_name: accountName,
            },
        };

        const response = await api.post('/auth/user', body);

        // fail, return response as error for error message
        if (response.status !== 'success') {
            return { userAuth: null, error: response.message };
        }

        // success, return user info
        return { userAuth: response.data, error: null };
    },
};

export default exports;
