/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';

import { reducer as authReducer } from '../app/data/auth';
// import { reducer as localReducer } from '../app/data/local';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
    // Initially we don't have any injectedReducers, so returning identity function to avoid the error
    if (Object.keys(injectedReducers).length === 0) {
        return state => state;
    } else {
        return combineReducers({
            ...injectedReducers,
        });
    }

    // const rootReducer = combineReducers({
    //   auth: authReducer,
    //   // local: localReducer,
    //   ...injectedReducers,
    // });

    // const persistConfig = {
    //   key: 'root',
    //   version: 1,
    //   storage,
    //   whitelist: ['auth'],
    // };

    // const persistedReducer = persistReducer(persistConfig, rootReducer);
    // return persistedReducer;
}
