import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthSlice, selectAuth, useAuthSelector } from 'app/data/auth';
import { useEffectOnce } from 'react-use';

import { useQueryParams, StringParam, NumberParam, ArrayParam, withDefault } from 'use-query-params';
import { useQuery, useQueryClient } from 'react-query';
import { request, gql } from 'graphql-request';
import jwt from 'jsonwebtoken';
import md5 from 'md5';

//  const query = useQuery('todos', getTodos);

function AuthGate({ children }) {
    const [ready, setReady] = React.useState(false);
    const dispatch = useDispatch();
    const { actions } = useAuthSlice();
    const authState = useAuthSelector();
    // const { actions } = useAuthSlice();
    const history = useHistory();

    const [qs, setQs] = useQueryParams({
        authToken: StringParam,
    });
    const { authToken } = qs;

    useEffectOnce(() => {
        (async () => {
            // Handle querystring login values
            // - may need to reset querystring
            if (qs.authToken) {
                // trigger auth change process
                // - remove authToken from querystring
                dispatch(actions.authTokenChange({ authToken: qs.authToken }));
                setQs({ authToken: undefined }, 'replaceIn'); // TODO: make this an action?
                setReady(true);
            } else {
                if (authState.loggedIn) {
                    // logged in, no auth token, just continue
                    setReady(true);
                } else {
                    // not logged in, no auth token, redirect to login page
                    // - handled by switch to redirect automatically (or maybe an About page, or something else is possible...)
                    console.log('AUTH: no credentials - probably redirect to login');
                    setReady(true);
                }
            }
        })();
    });

    if (!ready) {
        return null;
    }

    if (authState.loggingIn) {
        return <>Authenticating...</>;
    }

    // return <>CHILDREN</>;
    return children;
}

export default AuthGate;
