import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from '@voiplabscom/design';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@voiplabscom/design/icons-material';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { toast } from 'react-toastify';
import { useCreateUser } from 'app/hooks/mutations/users';
import { useUpdateUser } from 'app/hooks/mutations/users';
import { passwordStrength } from 'check-password-strength';
import { useAuthSelector } from 'app/data/auth';
import { UserType } from 'types/users';

// interface for useForm. Not required as it can be inferred but
// prevents typos and provides a layer of documentation and prevents
// incorrect registers
interface UserForm {
    first_name: string;
    last_name: string;
    email_address: string;
    username: string;
    password: string;
    password_confirm: string;
    type: string;
}

// interface declaring which props are required/allowed
interface EditUserDialogProps {
    user?: any;
    create?: boolean;
    onCancel: () => void;
    onComplete: () => void;
}

const EditUserDialog = ({ user, create, onCancel, onComplete }: EditUserDialogProps) => {
    const authState = useAuthSelector();
    const isCreate = create === true;
    const isSuperAdmin = authState.authTokenDecoded?.user_type === UserType.SuperAdmin;
    const createUser = useCreateUser();
    const updateUser = useUpdateUser();
    const mutateUser = isCreate ? createUser : updateUser;

    const [isUsernameDifferent, setUsernameDifferent] = useState(!isCreate && user.username !== user.email_address);
    const toggleUsernameDifferent = () => {
        const isDifferent = !isUsernameDifferent;
        setUsernameDifferent(isDifferent);
        if (!isDifferent) {
            setValue('username', user?.username ?? '');
        }
    };
    const [isUpdatingPassword, setUpdatingPassword] = useState(false);
    const toggleUpdatingPassword = () => {
        const isUpdating = !isUpdatingPassword;
        setUpdatingPassword(isUpdating);
        if (!isUpdating) {
            setValue('password', '');
            setValue('password_confirm', '');
        }
    };

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const toggleShowPasswordConfirm = () => {
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    // schema for form validation. Passed to useForm to only trigger submit when
    // the below conditions are met. Any known serverside constraints (min/max,
    // character limits, numbers only, etc.) should be added.
    const schema = Joi.object({
        first_name: Joi.string().required(),
        last_name: Joi.string().required(),
        email_address: Joi.string()
            .email({ tlds: { allow: false } })
            .required(),
        username: isUsernameDifferent ? Joi.string().required() : Joi.string().allow(''),
        password: isCreate || isUpdatingPassword ? Joi.string().trim().min(8).required() : Joi.string().allow(''),
        password_confirm: Joi.ref('password'),
        type: Joi.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors, isDirty },
        setValue,
        watch,
    } = useForm<UserForm>({
        defaultValues: {
            // set any null/undefined values to empty strings for valid values for input components
            first_name: user?.first_name ?? '',
            last_name: user?.last_name ?? '',
            email_address: user?.email_address ?? '',
            username: user?.username ?? '',
            password: '',
            password_confirm: '',
            type: user?.type ?? 'user',
        },
        resolver: joiResolver(schema), // pass in custom validation
    });
    const watchPassword = watch('password');

    const onSubmit = formData => {
        const mutatePayload = isCreate
            ? {
                  user: { ...formData },
              }
            : {
                  userId: user.id,
                  user: { ...formData },
              };

        if (!mutatePayload.user.username) {
            mutatePayload.user.username = mutatePayload.user.email_address;
        }
        if (!mutatePayload.user.password) {
            delete mutatePayload.user.password;
        }
        delete mutatePayload.user.password_confirm;

        mutateUser.mutate(mutatePayload, {
            onSuccess: () => {
                toast.success(`User successfully ${isCreate ? 'created' : 'updated'}.`);
                onComplete();
            },
            onError: error => {
                const errorMessage =
                    error?.response?.data?.message ?? `Failed to ${isCreate ? 'create' : 'update'} User.`;
                toast.error(errorMessage);
            },
        });
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog open={true} fullWidth maxWidth={'sm'}>
            {mutateUser.isLoading ? (
                <div
                    style={{
                        width: '100%',
                        height: 100,
                        display: 'grid',
                        placeItems: 'center',
                    }}
                >
                    <CircularProgress variant={'indeterminate'} />
                    <Typography>{isCreate ? 'Creating' : 'Updating'} User...</Typography>
                </div>
            ) : (
                <>
                    <DialogTitle>{isCreate ? 'Create new' : 'Edit'} User</DialogTitle>
                    <Divider />
                    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <TextField
                                        label={'First Name'}
                                        {...register('first_name')}
                                        error={!!formErrors.first_name}
                                        helperText={formErrors.first_name?.message?.replace(
                                            '"first_name"',
                                            'First Name',
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label={'Last Name'}
                                        {...register('last_name')}
                                        error={!!formErrors.last_name}
                                        helperText={formErrors.last_name?.message?.replace('"last_name"', 'Last Name')}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={1}>
                                <Grid item md={6}>
                                    <TextField
                                        label={'Email Address'}
                                        type={'email'}
                                        {...register('email_address')}
                                        error={!!formErrors.email_address}
                                        helperText={formErrors.email_address?.message?.replace(
                                            '"email_address"',
                                            'Email Address',
                                        )}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item md={5}>
                                    {isUsernameDifferent ? (
                                        <TextField
                                            label={'Username'}
                                            {...register('username')}
                                            error={!!formErrors.username}
                                            helperText={formErrors.username?.message?.replace('"username"', 'Username')}
                                        />
                                    ) : (
                                        <Typography
                                            mt={0.5}
                                            ml={1}
                                            onClick={toggleUsernameDifferent}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            Login with a different username?
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item md={1}>
                                    <Checkbox
                                        checked={isUsernameDifferent}
                                        onChange={toggleUsernameDifferent}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 32 }, float: 'right', mr: '-10px' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={1}>
                                {!isCreate ? (
                                    <>
                                        <Grid item md={1}>
                                            <Checkbox
                                                checked={isUpdatingPassword}
                                                onChange={toggleUpdatingPassword}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 32 }, ml: '-10px' }}
                                            />
                                        </Grid>
                                        {!isUpdatingPassword ? (
                                            <Typography
                                                mt={1}
                                                ml={1}
                                                onClick={toggleUpdatingPassword}
                                                sx={{ cursor: 'pointer', lineHeight: '64px' }}
                                            >
                                                Also update the password?
                                            </Typography>
                                        ) : null}
                                    </>
                                ) : null}
                                {isCreate || isUpdatingPassword ? (
                                    <>
                                        <Grid item md={isCreate ? 6 : 5}>
                                            <TextField
                                                label={isUpdatingPassword ? 'New Password' : 'Password'}
                                                type={showPassword ? 'text' : 'password'}
                                                {...register('password')}
                                                error={!!formErrors.password}
                                                helperText={
                                                    formErrors.password?.message?.replace('"password"', 'Password') ??
                                                    watchPassword
                                                        ? passwordStrength(watchPassword).value
                                                        : null
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            size={'small'}
                                                            onClick={toggleShowPassword}
                                                            tabIndex={-1}
                                                        >
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    ),
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField
                                                label={isUpdatingPassword ? 'Confirm New Password' : 'Confirm Password'}
                                                type={showPassword ? 'text' : 'password'}
                                                {...register('password_confirm')}
                                                error={!!formErrors.password_confirm}
                                                helperText={formErrors.password_confirm?.message?.replace(
                                                    '"password_confirm"',
                                                    'Password Confirmation',
                                                )}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            size={'small'}
                                                            onClick={toggleShowPassword}
                                                            tabIndex={-1}
                                                        >
                                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    ),
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                            <Grid container spacing={2} mt={1}>
                                <Grid item md={4}>
                                    <TextField
                                        select
                                        label="User Type"
                                        {...register('type')}
                                        defaultValue={user?.type ?? 'user'}
                                        fullWidth
                                        // sx={{ display: 'inline-flex', width: 200 }}
                                    >
                                        <MenuItem value="user">User</MenuItem>
                                        <MenuItem value="admin">Admin</MenuItem>
                                        {/* <MenuItem value="superadmin">SuperAdmin</MenuItem> */}
                                        {isSuperAdmin ? <MenuItem value="superadmin">SuperAdmin</MenuItem> : null}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ justifyContent: 'space-between' }}>
                            <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
                                Cancel
                            </Button>
                            {mutateUser.error ? (
                                <Typography color={'error'}>
                                    {`${mutateUser.error.message} ${
                                        mutateUser.error.response ? `: ${mutateUser.error.response?.statusText}` : ''
                                    }`}
                                </Typography>
                            ) : null}
                            <Button variant={'outlined'} color={'success'} disabled={!isDirty} type={'submit'}>
                                {isCreate ? 'Create' : 'Update'}
                            </Button>
                        </DialogActions>
                    </Box>
                </>
            )}
        </Dialog>
    );
};

export default EditUserDialog;
