// stateless component
import { Box, CircularProgress, FormControlLabel, Switch, Tooltip } from '@voiplabscom/design';
import { Check as CheckIcon, Clear as ClearIcon } from '@voiplabscom/design/icons-material';
import * as React from 'react';
import { ChangeEvent } from 'react';
interface LoadingSwitchProps {
    disabled?: boolean;
    checked: boolean;
    loading?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    inputProps: any;
    sx?: any;
}
// TODO abstract out Tooltip
const LoadingSwitch = ({
    disabled = false,
    checked,
    loading = false,
    onChange,
    inputProps,
    sx,
}: LoadingSwitchProps) => {
    const progressPosition =
        inputProps.size && inputProps.size === 'small' ? { top: -2, right: 5 } : { top: 5, right: 15 };
    return (
        <Box sx={sx}>
            <Box sx={{ position: 'relative' }}>
                <Switch
                    {...inputProps}
                    checked={checked}
                    onChange={event => onChange(event)}
                    disabled={disabled || loading}
                />
                <CircularProgress
                    variant={'indeterminate'}
                    sx={{
                        position: 'absolute',
                        ...progressPosition,
                        display: loading === true ? 'block' : 'none',
                    }}
                    size={28}
                />
            </Box>
        </Box>
    );
};
export default LoadingSwitch;
