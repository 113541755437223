import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import { AuthState } from './types';
import jwt from 'jsonwebtoken';

// export { AuthActionCreators };

export const persistInitialState = {
    // persisted (NEED to be added to persistMask in `configureStore`)
    loggedIn: false,
    authToken: null,
    authTokenDecoded: null,
    loginVia: null, // authToken, credentials
    currentAccountId: null,
    currentUserId: null,
};

// Persistence defined in `persistMask` in `configureStore.ts`
export const initialState: AuthState = {
    ...persistInitialState,

    // ephemeral
    loggingIn: false,
    error: null,
};

const setAuthTokenDecoded = (state, authToken) => {
    if (!authToken) {
        state.authTokenDecoded = null;
        state.currentAccountId = null;
        state.currentUserId = null;
    } else {
        state.authTokenDecoded = jwt.decode(authToken);
        state.currentAccountId = state.authTokenDecoded.account_id;
        state.currentUserId = state.authTokenDecoded.user_id;
    }
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // someAction(state, action: PayloadAction<any>) {},
        setAuthToken(state, action) {
            console.log('setAuthToken:', action.payload);
            state.authToken = action.payload.authToken;
            setAuthTokenDecoded(state, state.authToken);
            state.loggedIn = true;
            state.loggingIn = false;
            state.error = null;
        },
        authTokenChange(state, action) {},
        silentlyUpdateAuthToken(state, action) {
            state.authToken = action.payload.authToken;
            setAuthTokenDecoded(state, state.authToken);
        },
        loginWithAuthToken(state, action) {
            state.loggedIn = false;
            state.loggingIn = true;
            state.authToken = action.payload.authToken;
            setAuthTokenDecoded(state, state.authToken);
            state.error = null;
            console.log('attempting to login in with auth token.', action);
        },
        loginFail(state, action) {
            state.loggedIn = false;
            state.loggingIn = false;
            state.authToken = null;
            state.authTokenDecoded = null;
            setAuthTokenDecoded(state, null);
            state.error = action.payload.error;
            console.log('Login failed.', action);
        },
        loginSuccess(state, action) {
            state.loggedIn = true;
            state.loggingIn = false;
            console.log('action', action);
            state.authToken = action.payload.authToken;
            setAuthTokenDecoded(state, state.authToken);
            state.error = null;
            console.log('Login was successful.', action);
        },
        loginWithCredentials(state, action) {
            state.loggedIn = false;
            state.loggingIn = true;
            state.authToken = null;
            state.authTokenDecoded = null;
            setAuthTokenDecoded(state, null);
            state.error = null;
            console.log('attempting to login in with credentials...', action);
        },
        logout(state, action) {
            console.log('logging out...', action);
            return {
                ...initialState,
            };
        },
    },
});

export const { actions, reducer } = slice;

export const useAuthSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: authSaga });
    // const state = useSelector(selectAuth); // dont want to include here cuz runs selector unnecessarily?
    // return { AuthActionCreators };
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
