import { Box, Card, CardContent, Tooltip, Typography, useTheme } from '@voiplabscom/design';

const MainContentCard = props => {
    const theme = useTheme();
    return (
        <Card sx={{ borderRadius: '10px', marginTop: '20px', ...props.sx, overflow: 'initial' }}>
            <CardContent sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.background.default,
                        padding: '20px',
                        borderRadius: '10px',
                        position: 'absolute',
                        top: '-20px',
                        left: '20px',
                        right: '20px',
                    }}
                >
                    <Typography component="h6" variant="h6">
                        {props.title}
                    </Typography>
                </Box>
                <Box sx={{ paddingTop: '60px' }}>{props.children}</Box>
            </CardContent>
        </Card>
    );
};
export default MainContentCard;
