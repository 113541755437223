import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@voiplabscom/design';

function Confirm({
    title,
    children,
    cancelText,
    cancelColor,
    cancelVariant,
    onCancel,
    confirmText,
    confirmColor,
    confirmVariant,
    onConfirm,
}) {
    const handleCancel = () => {
        onCancel && onCancel();
    };

    const handleConfirm = () => {
        onConfirm && onConfirm();
    };

    return (
        <Dialog
            open={true}
            onClose={handleCancel}
            aria-labelledby="confirm_dialog_title"
            aria-describedby="confirm_dialog_description"
        >
            <DialogTitle id="confirm_dialog_title">{title ?? 'Confirm?'}</DialogTitle>
            <Divider />
            <DialogContent id="confirm_dialog_description">{children}</DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel} color={cancelColor ?? ''} variant={cancelVariant ?? ''} autoFocus>
                    {cancelText ?? 'Cancel'}
                </Button>
                <Button onClick={handleConfirm} color={confirmColor ?? ''} variant={confirmVariant ?? ''}>
                    {confirmText ?? 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default Confirm;
