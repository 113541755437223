import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { useQuery } from 'react-query';
import usersQueryKeys from './usersQueryKeys';

export const useUsersListQuery = (options = {} as any) => {
    const authState = useAuthSelector();
    const { authToken, currentAccountId } = authState;
    const accountId = (options.accountId ?? currentAccountId) as string;
    const baseQueryKey = usersQueryKeys.list(accountId);
    const queryKey = options.params ? [...baseQueryKey, options.params] : baseQueryKey;
    const query = useQuery(queryKey, async () => sdk.users.list(accountId, { authToken }), {});

    return {
        ...query,
        queryKey,
    };
};

export default useUsersListQuery;
