import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTheme } from '@voiplabscom/design';

import {
    Avatar,
    Box,
    Drawer,
    Divider,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    Collapse,
    Grid,
    Toolbar,
} from '@voiplabscom/design';
import {
    Dashboard as DashboardIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Public as PublicIcon,
    PhoneInTalk as PhoneInTalkIcon,
    Security as SecurityIcon,
    DocumentScanner as DocumentScannerIcon,
    ManageAccounts as ManageAccountsIcon,
    MonetizationOn as MonetizationOnIcon,
    Logout as LogoutIcon,
    Group as GroupIcon,
    FactCheck as FactCheckIcon,
} from '@voiplabscom/design/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';

const drawerWidth = 240;

export const Sidebar = () => {
    const theme = useTheme();
    const { pathname: currentPath } = useLocation();
    const [openGroups, setOpenGroups] = useState([] as any[]);

    const tabsItems = sidebarTabs.map((tab, i) => (
        <SidebarListItem key={i} {...tab} openGroups={openGroups} setOpenGroups={setOpenGroups} theme={theme} />
    ));

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.background.default,
                },
            }}
        >
            <Toolbar />
            <Box
                sx={{
                    overflow: 'auto',
                    margin: '8px',
                    borderRadius: '10px',
                    backgroundImage: 'linear-gradient(195deg, #42424a 0%, #191919 100%)',
                }}
            >
                <PerfectScrollbar options={{ wheelPropagation: false }}>
                    <List
                        sx={{
                            padding: '8px',
                            color: theme.palette.background.default,
                            '& .MuiListItemIcon-root': {
                                color: theme.palette.background.default,
                            },
                            '& .MuiListItem-button': {
                                borderRadius: '6px',
                                marginTop: '4px',
                            },
                            '& .MuiListItem-button:hover': {
                                backgroundColor: '#49494b',
                            },
                            '& .MuiListItemText-root': {
                                margin: 0,
                            },
                            letterSpacing: 'normal',
                        }}
                    >
                        {tabsItems}
                    </List>
                </PerfectScrollbar>
            </Box>
        </Drawer>
    );
};

const SidebarListItem = props => {
    const {
        text,
        subheading,
        route,
        type,
        icon,
        // iconLetter,
        // padTop,
        // tour,
        // selectKey,
        // selectedSidebarKey,
        depth = 0,
        // isAdminUrl,
        // openGroups,
        // setOpenGroups,
        children = [] as any[],
        theme,
    } = props;

    // const isSelected = selectedSidebarKey === selectKey ? true : false;
    // const childSelected = children.find(c => c.selectKey === selectedSidebarKey); // todo: handle deeper w/ recursion

    // const handleToggleShowHide = () => {
    //     if (openGroups.includes(selectKey)) {
    //         setOpenGroups([...openGroups.filter(item => item !== selectKey)]);
    //     } else {
    //         setOpenGroups([...openGroups, selectKey]);
    //     }
    // };

    // const childrenFiltered = children.filter(c => c.show({ isAdminUrl }));

    switch (type) {
        case 'heading': {
            return (
                <ListItem sx={{ marginTop: '8px' }}>
                    <div style={{ width: depth * 24 }}>&nbsp;</div>
                    {icon ? <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon> : null}
                    <ListItemText
                        primaryTypographyProps={{
                            sx: { fontSize: '14px', fontWeight: 'bold', textTransform: 'uppercase' },
                        }}
                        primary={text}
                        secondary={subheading}
                    />
                </ListItem>
            );
        }

        case 'link': {
            return (
                <ListItem
                    button
                    disableRipple
                    component={NavLink}
                    to={route}
                    activeStyle={{
                        backgroundColor: theme.palette.primary.main,
                    }}
                >
                    <div style={{ width: depth * 24 }}>&nbsp;</div>
                    {icon ? <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon> : null}
                    <ListItemText
                        primaryTypographyProps={{ sx: { fontSize: '14px' } }}
                        primary={text}
                        secondary={subheading}
                    />
                </ListItem>
            );
        }

        // case 'group': {
        //     return (
        //         <>
        //             <ListItem button disableRipple sx={{ marginTop: '8px' }}>
        //                 <div style={{ width: depth * 24 }}>&nbsp;</div>
        //                 {icon ? <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon> : null}
        //                 <ListItemText
        //                     primaryTypographyProps={{
        //                         sx: { fontSize: '14px' },
        //                     }}
        //                     primary={text}
        //                     secondary={subheading}
        //                 />
        //                 {/* {openGroups.includes(selectKey) ? (
        //                     <ExpandLessIcon onClick={handleToggleShowHide} />
        //                 ) : (
        //                     <ExpandMoreIcon onClick={handleToggleShowHide} />
        //                 )} */}
        //             </ListItem>
        //             {children && children.length ? (
        //                 <Collapse timeout="auto" sx={{ minHeight: 'auto' }}>
        //                     {children.map((child, i) => (
        //                         <SidebarListItem
        //                             key={i}
        //                             {...child}
        //                             depth={depth + 1}
        //                             theme={theme}
        //                             // openGroups={openGroups}
        //                             // setOpenGroups={setOpenGroups}
        //                         />
        //                     ))}
        //                 </Collapse>
        //             ) : (
        //                 ''
        //             )}
        //         </>
        //     );
        // }

        default: {
            return <></>;
        }
    }
};

const sidebarTabs = [
    {
        type: 'link',
        text: 'Dashboard',
        route: '/dashboard',
        icon: <DashboardIcon />,
    },
    /****************************************** */
    {
        type: 'heading',
        text: 'Phone Network',
    },
    {
        type: 'link',
        text: 'Carriers',
        route: '/voice/outbound/carriers',
        icon: <PublicIcon />,
    },
    /****************************************** */
    {
        type: 'heading',
        text: 'Call Routing',
    },
    {
        type: 'link',
        text: 'Endpoints',
        route: '/call_routing/endpoints',
        icon: <PhoneInTalkIcon />,
    },
    /****************************************** */
    {
        type: 'heading',
        text: 'Information Services',
    },
    {
        type: 'link',
        text: 'Caller Authentication',
        route: '/tbd/call_auth',
        icon: <SecurityIcon />,
    },
    /****************************************** */
    {
        type: 'heading',
        text: 'Reporting Services',
    },
    {
        type: 'link',
        text: 'Call Detail Records',
        route: '/tbd/cdr',
        icon: <DocumentScannerIcon />,
    },
    /****************************************** */
    {
        type: 'heading',
        text: 'Administration',
    },
    {
        type: 'link',
        text: 'Access Control',
        route: '/voice/outbound/access_control',
        icon: <FactCheckIcon />,
    },
    {
        type: 'link',
        text: 'Users',
        route: '/users',
        icon: <GroupIcon />,
    },
    /****************************************** */
    {
        type: 'heading',
        text: 'Your Account',
    },
    {
        type: 'link',
        text: 'Account',
        route: '/tbd/account',
        icon: <GroupIcon />,
    },
    {
        type: 'link',
        text: 'Profile',
        route: '/tbd/profile',
        icon: <ManageAccountsIcon />,
    },
    {
        type: 'link',
        text: 'Billing',
        route: '/tbd/billing',
        icon: <MonetizationOnIcon />,
    },
    {
        type: 'link',
        text: 'Logout',
        route: '/logout',
        icon: <LogoutIcon />,
    },
    // {
    //     type: 'group',
    //     text: 'Test Group',
    //     route: '/group',
    //     icon: <PublicIcon />,
    //     children: [
    //         {
    //             text: 'Foo',
    //             type: 'link',
    //             route: '/group/foo',
    //             icon: <PublicIcon />,
    //         },
    //         {
    //             text: 'Bar',
    //             type: 'link',
    //             route: '/group/bar',
    //             icon: <PublicIcon />,
    //         },
    //     ],
    // },
];

export default Sidebar;
