import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { CustomerEndpointUpdate } from 'types/customer_endpoint';
import { AxiosError } from 'axios';

interface CustomerEndpointUpdateData {
    accountId?: string;
    endpointId?: string;
    endpoint: CustomerEndpointUpdate;
}

export const useUpdateCustomerEndpoint = () => {
    const authState = useAuthSelector();
    return useMutation<any, AxiosError, CustomerEndpointUpdateData, unknown>((data: CustomerEndpointUpdateData) =>
        sdk.customer_endpoints.patch(
            data.accountId ?? authState.currentAccountId,
            data.endpointId ?? data.endpoint.id,
            { data: data.endpoint },
            {
                authToken: authState.authToken,
            },
        ),
    );
};
