import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { CustomerEndpointCreate } from 'types/customer_endpoint';
import { AxiosError } from 'axios';

interface CustomerEndpointCreateData {
    accountId?: string;
    endpoint: CustomerEndpointCreate;
}

export const useCreateCustomerEndpoint = () => {
    const authState = useAuthSelector();
    return useMutation<any, AxiosError, CustomerEndpointCreateData, unknown>((data: CustomerEndpointCreateData) =>
        sdk.customer_endpoints.create(
            data.accountId ?? authState.currentAccountId,
            { data: data.endpoint },
            {
                authToken: authState.authToken,
            },
        ),
    );
};
