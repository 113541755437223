import { api } from 'app/sdk/utils/trunkingio';

const exports = {
    list: async (accountId, options) => {
        const response = await api.get(`/accounts/${accountId}/active_carriers`, options);
        return response.data;
    },
    create: async (accountId, carrierId, options) => {
        const response = await api.put(`/accounts/${accountId}/active_carriers/${carrierId}`, null, options);
        return response.data;
    },
    bulkCreate: async (accountId, payload, options) => {
        const response = await api.put(`/accounts/${accountId}/active_carriers/bulk`, payload, options);
        return response.data;
    },
    delete: async (accountId, carrierId, options) => {
        const response = await api.delete(`/accounts/${accountId}/active_carriers/${carrierId}`, options);
        return response.data;
    },
    bulkDelete: async (accountId, payload, options) => {
        const response = await api.delete(`/accounts/${accountId}/active_carriers/bulk`, options, payload);
        return response.data;
    },
};

export default exports;
