import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { UserUpdate } from 'types/users';
import { AxiosError } from 'axios';

interface UserUpdateData {
    accountId?: string;
    userId?: string;
    user: UserUpdate;
}

export const useUpdateUser = () => {
    const authState = useAuthSelector();
    return useMutation<any, AxiosError, UserUpdateData, unknown>((data: UserUpdateData) =>
        sdk.users.patch(
            data.accountId ?? authState.currentAccountId,
            data.userId ?? data.user.id,
            { data: data.user },
            {
                authToken: authState.authToken,
            },
        ),
    );
};
