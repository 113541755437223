import axios from 'axios';

const buildAxiosConfig = (method, url, config, data) => {
    const axiosPayload = {
        method: method,
        url: `${process.env.REACT_APP_API_SERVER}${url}`,
        params: config.params,
        headers: {
            'Content-Type': config.contentType ?? 'application/json',
        },
    };
    if (config.authToken) {
        // @ts-ignore
        axiosPayload.headers.Authorization = 'Bearer ' + config.authToken;
    }
    if (data) {
        // @ts-ignore
        axiosPayload.data = data;
    }

    return axiosPayload;
};

const callApi = async (method, url, config, data = null) => {
    const axiosPayload = buildAxiosConfig(method, url, config, data);

    const response = await axios(axiosPayload).catch(error => {
        console.log('API Error:', error.response.data);
        if (config.onError) {
            config.onError(error);
            return error;
        }
        throw error;
    });

    config.onSuccess && config.onSuccess(response);
    return response.data;
};

export const methods = {
    get: async (url, config = {}) => {
        return callApi('get', url, config);
    },
    patch: async (url, data, config = {}) => {
        return callApi('patch', url, config, data);
    },
    put: async (url, data, config = {}) => {
        return callApi('put', url, config, data);
    },
    post: async (url, data, config = {}) => {
        return callApi('post', url, config, data);
    },
    delete: async (url, config = {}, data = null) => {
        return callApi('delete', url, config, data);
    },
};
