import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';
import { UserCreate } from 'types/users';
import { AxiosError } from 'axios';

interface UserCreateData {
    accountId?: string;
    user: UserCreate;
}

export const useCreateUser = () => {
    const authState = useAuthSelector();
    return useMutation<any, AxiosError, UserCreateData, unknown>((data: UserCreateData) =>
        sdk.users.create(
            data.accountId ?? authState.currentAccountId,
            { data: data.user },
            {
                authToken: authState.authToken,
            },
        ),
    );
};
