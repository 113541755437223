import { auth } from './resources/auth';
import { customer_endpoints } from './resources/customer_endpoints';
import { users } from './resources/users';
import { carriers } from './resources/carriers';
import { active_carriers } from './resources/active_carriers';

const sdk = {
    auth,
    customer_endpoints,
    users,
    carriers,
    active_carriers,
};

export default sdk;
