import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@voiplabscom/design';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { toast } from 'react-toastify';
import { useCreateCustomerEndpoint } from 'app/hooks/mutations/customer_endpoints';
import { useUpdateCustomerEndpoint } from 'app/hooks/mutations/customer_endpoints';

// schema for form validation. Passed to useForm to only trigger submit when
// the below conditions are met. Any known serverside constraints (min/max,
// character limits, numbers only, etc.) should be added.
const schema = Joi.object({
    ip: Joi.string()
        .ip({
            version: ['ipv4'],
            cidr: 'forbidden',
        })
        .required()
        .messages({
            'string.ipVersion': 'Invalid IP address format',
            'string.empty': 'Required',
        }),
    port: Joi.number().port().required().messages({
        'number.base': 'Required',
        'number.port': 'Port must be between 1 and 65535',
    }),
    protocol: Joi.string().valid('tcp', 'udp').required().messages({
        'any.only': 'Required',
    }),
    name: Joi.string().allow('').max(255),
    description: Joi.string().allow('').max(255),
    direction: Joi.string().valid('both', 'inbound', 'outbound').required().messages({
        'any.only': 'Required',
    }),
});

// interface for useForm. Not required as it can be inferred but
// prevents typos and provides a layer of documentation and prevents
// incorrect registers
interface EditCustomerEndpointForm {
    ip: string;
    port: number;
    protocol: string;
    name: string;
    description: string;
    direction: string;
}

// interface declaring which props are required/allowed
interface EditCustomerEndpointDialogProps {
    endpoint?: any;
    create?: boolean;
    onCancel: () => void;
    onComplete: () => void;
}

const EditCustomerEndpointDialog = ({ endpoint, create, onCancel, onComplete }: EditCustomerEndpointDialogProps) => {
    const isCreate = create === true;
    const createCustomerEndpoint = useCreateCustomerEndpoint();
    const updateCustomerEndpoint = useUpdateCustomerEndpoint();
    const mutateCustomerEndpoint = isCreate ? createCustomerEndpoint : updateCustomerEndpoint;

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors, isDirty },
        setError,
        watch,
    } = useForm<EditCustomerEndpointForm>({
        defaultValues: {
            // set any null/undefined values to empty strings for valid values for input components
            ip: endpoint?.ip ?? '',
            port: endpoint?.port ?? '',
            protocol: endpoint?.protocol ?? '',
            name: endpoint?.name ?? '',
            description: endpoint?.description ?? '',
            direction: endpoint?.direction ?? '',
        },
        resolver: joiResolver(schema), // pass in custom validation
    });

    const onSubmit = formData => {
        const mutatePayload = isCreate
            ? {
                  endpoint: formData,
              }
            : {
                  endpointId: endpoint.id,
                  endpoint: formData,
              };

        mutateCustomerEndpoint.mutate(mutatePayload, {
            onSuccess: () => {
                toast.success(`Endpoint successfully ${isCreate ? 'created' : 'updated'}.`);
                onComplete();
            },
            onError: error => {
                const errorMessage =
                    error?.response?.data?.message ?? `Failed to ${isCreate ? 'create' : 'update'} Endpoint.`;
                toast.error(errorMessage);
            },
        });
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog open={true} fullWidth maxWidth={'sm'}>
            {mutateCustomerEndpoint.isLoading ? (
                <div
                    style={{
                        width: '100%',
                        height: 100,
                        display: 'grid',
                        placeItems: 'center',
                    }}
                >
                    <CircularProgress variant={'indeterminate'} />
                    <Typography>{isCreate ? 'Creating' : 'Updating'} Endpoint...</Typography>
                </div>
            ) : (
                <>
                    <DialogTitle>{isCreate ? 'Create new' : 'Edit'} Endpoint</DialogTitle>
                    <Divider />
                    <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
                            <Box component="div" sx={{ maxWidth: 600 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="IP address"
                                            {...register('ip')}
                                            error={!!formErrors.ip}
                                            helperText={formErrors.ip?.message?.replace('"ip"', 'IP address')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            type="number"
                                            label="Port"
                                            {...register('port')}
                                            error={!!formErrors.port}
                                            helperText={formErrors.port?.message?.replace('"port"', 'Port')}
                                            inputProps={{ min: 1, max: 65535 }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            label="Protocol"
                                            {...register('protocol')}
                                            error={!!formErrors.protocol}
                                            helperText={formErrors.protocol?.message?.replace('"protocol"', 'Protocol')}
                                            defaultValue={endpoint?.protocol ?? ''}
                                            fullWidth
                                        >
                                            <MenuItem value="tcp">TCP</MenuItem>
                                            <MenuItem value="udp">UDP</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <TextField
                                            label="Name"
                                            {...register('name')}
                                            error={!!formErrors.name}
                                            helperText={formErrors.name?.message?.replace('"name"', 'Name')}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            select
                                            label="Direction"
                                            {...register('direction')}
                                            error={!!formErrors.direction}
                                            helperText={formErrors.direction?.message?.replace(
                                                '"direction"',
                                                'Direction',
                                            )}
                                            defaultValue={endpoint?.direction ?? ''}
                                            fullWidth
                                        >
                                            <MenuItem value="both">Both</MenuItem>
                                            <MenuItem value="inbound">Inbound</MenuItem>
                                            <MenuItem value="outbound">Outbound</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Description"
                                            {...register('description')}
                                            error={!!formErrors.description}
                                            helperText={formErrors.description?.message?.replace(
                                                '"description"',
                                                'Description',
                                            )}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ justifyContent: 'space-between' }}>
                            <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
                                Cancel
                            </Button>
                            {mutateCustomerEndpoint.error ? (
                                <Typography color={'error'}>
                                    {`${mutateCustomerEndpoint.error.message} ${
                                        mutateCustomerEndpoint.error.response
                                            ? `: ${mutateCustomerEndpoint.error.response?.statusText}`
                                            : ''
                                    }`}
                                </Typography>
                            ) : null}
                            <Button variant={'outlined'} color={'success'} disabled={!isDirty} type={'submit'}>
                                {isCreate ? 'Create' : 'Update'}
                            </Button>
                        </DialogActions>
                    </Box>
                </>
            )}
        </Dialog>
    );
};

export default EditCustomerEndpointDialog;
