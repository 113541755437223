import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { actions } from './index';
import jwt from 'jsonwebtoken';

// import { getUserByAuthToken } from 'app/sdk/utils';
// import { authenticateUserWithCredentials } from 'app/sdk/utils/kazoo';
import { sdk } from 'app/sdk';

function* handleLoginWithAuthToken(action) {
    const { authToken } = action.payload;

    try {
        // kazoo api fetch for user auth
        const { userAuth, error } = yield call(sdk.user.query.userByAuthTokenFromKazoo, authToken);

        // fetch successful
        if (userAuth) {
            console.log('authToken result', userAuth);
            yield put(
                actions.loginSuccess({
                    authToken: userAuth.authToken,
                }),
            );
            return;
        }

        // fetch failed
        yield put(actions.loginFail({ error: error.message }));
    } catch (error) {
        // error thrown
        yield put(actions.loginFail({ error: error.message }));
    }
}

function* handleLoginWithCredentials(action) {
    const { username, accountName, password } = action.payload;
    console.log('handleLogin');
    try {
        // kazoo api fetch for user auth
        const { userAuth, error } = yield call(sdk.auth.byUser, username, accountName, password);

        console.log('userAuth', userAuth);

        // fetch successful
        if (userAuth) {
            yield put(
                actions.loginSuccess({
                    authToken: userAuth.token,
                }),
            );
            return;
        }

        // fetch failed
        yield put(actions.loginFail({ error: error.message }));
    } catch (error) {
        // error thrown
        yield put(actions.loginFail({ error: error.message }));
    }
}

const jwtDecode = authToken => {
    try {
        return jwt.decode(authToken);
    } catch (e) {
        console.error(e);
        return null;
    }
};

function* handleAuthTokenChange(action) {
    // when a new authToken arrives
    // - tell the user we are authenticating (when new user, or user in authToken changed)
    //   - also clear the cache via LOGOUT action (todo: CLEAR_CACHE instead of LOGOUT?)
    // - or, silently update the authToken in the background, if it fails on requests then the user is logged out (and sent to the login page)
    const { authToken } = action.payload;
    const state = yield select();
    const { auth: authState } = state;
    console.log('handleAuthTokenChange authState:', authState);

    try {
        if (authToken) {
            const authTokenDecoded = jwtDecode(authToken);

            if (authTokenDecoded) {
                // user is different?
                if (authTokenDecoded.owner_id === authState.authTokenDecoded?.owner_id) {
                    // user is same

                    // is expiration newer?
                    if (authTokenDecoded.exp > authState.authTokenDecoded.exp) {
                        // newer expiration

                        // silently update the authToken locally
                        yield put(actions.silentlyUpdateAuthToken({ authToken }));
                    } else {
                        // older expiration, dont do anything, kinda weird to get an authToken here...
                        // - TODO: remove authToken from querystring? currently already doing...
                    }
                } else {
                    // user is different or user doesn't exist

                    // log out current user (clear data)
                    yield put(actions.logout(null));

                    // login with auth token
                    yield put(actions.loginWithAuthToken({ authToken }));
                }
            } else {
                // invalid auth token
                yield put(actions.logout(null));
            }

            // const { exp } = authTokenDecoded;
            // const currentTimestamp = Math.ceil(new Date().getTime() / 1000);
            // const expirationBuffer = 10; // seconds
            // const expirationTimestamp = exp - expirationBuffer;
            // console.log('auth exp timestamp & current', exp, currentTimestamp);
            // if (currentTimestamp >= expirationTimestamp) {
            //   console.log('auth token expired');
            // } else {
            //   console.log('auth token still valid');
            // }
        }
    } catch (e) {}
}

export function* authSaga() {
    // takeLatest means only the most recent of multiple dispatch will be run,
    // still running previously dispatched actions will be canceled
    yield takeLatest(actions.authTokenChange.type, handleAuthTokenChange);
    yield takeLatest(actions.loginWithAuthToken.type, handleLoginWithAuthToken);
    yield takeLatest(actions.loginWithCredentials.type, handleLoginWithCredentials);
}
