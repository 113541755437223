/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Switch, Route, Redirect } from 'react-router-dom';

import { Layout } from './components/Layout';
import { Login } from './pages/Login';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useAuthSelector } from './data/auth';
import { AccessControlList } from './pages/voice/outbound/access_control/List';
import { UserList } from './pages/users/List';
import { CarriersList } from './pages/voice/outbound/carriers/List';
import { CarrierDetails } from './pages/voice/outbound/carriers/Details';
import { EndpointList } from './pages/call_routing/endpoints/List';

function Routing() {
    const authState = useAuthSelector();

    return (
        <>
            {authState.loggedIn ? (
                <Layout>
                    <Switch>
                        {/* <Route
                            exact
                            path="/"
                            render={() => {
                                if (isLoading) {
                                    console.log('Loading user information before redirecting');
                                    return null;
                                }
                                console.log('user', user);
                                return user?.doc?.priv_level === 'admin' ? (
                                    <Redirect to="/admin" />
                                ) : (
                                    <Redirect to="/office" />
                                );
                            }}
                        /> */}
                        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                        <Route
                            exact
                            path="/dashboard"
                            render={() => {
                                return <>DashboardHere</>;
                            }}
                        />
                        <Route exact path="/voice/outbound/access_control" component={AccessControlList} />
                        <Route exact path="/voice/outbound/carriers" component={CarriersList} />
                        <Route exact path="/voice/outbound/carriers/:carrierId" component={CarrierDetails} />
                        <Route exact path="/call_routing/endpoints" component={EndpointList} />
                        <Route exact path="/users" component={UserList} />
                        {/* Redirecting login below (might not be necessary...) */}
                        <Route exact path="/login" render={() => <Redirect to="/" />} />
                        <Route component={NotFoundPage} />
                    </Switch>
                </Layout>
            ) : (
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route render={() => <Redirect to="/login" />} />
                </Switch>
            )}
        </>
    );
}

export default Routing;
