import { Box, Typography } from '@voiplabscom/design';

function CarrierDetails({
    match: {
        params: { carrierId },
    },
}) {
    return (
        <Box component="div">
            <Typography component="h4" variant="h4" sx={{ mb: 4 }}>
                Carrier: {carrierId}
            </Typography>
        </Box>
    );
}

export default CarrierDetails;
