import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@voiplabscom/design';
import { AddBox as AddBoxIcon, Delete as DeleteIcon, Edit as EditIcon } from '@voiplabscom/design/icons-material';
import { useState, useReducer } from 'react';
import { useCustomerEndpointsListQuery } from 'app/hooks/queries/customer_endpoints';
import { useDeleteCustomerEndpoint } from 'app/hooks/mutations/customer_endpoints';
import { Confirm } from 'app/components/common/Confirm';
import { toast } from 'react-toastify';
import { EditCustomerEndpointDialog } from 'app/components/customer_endpoint/EditCustomerEndpointDialog';

function AccessControlList() {
    const [showConfirm, toggleShowConfirm] = useReducer(show => !show, false);
    const [confirm, setConfirm] = useState({});
    const [showEndpointDialog, toggleShowEndpointDialog] = useReducer(show => !show, false);
    const [endpointDialog, setEndpointDialog] = useState({});
    const deleteCustomerEndpoint = useDeleteCustomerEndpoint();

    const { data: endpoints, isLoading, error, refetch } = useCustomerEndpointsListQuery();

    const askDelete = endpoint => {
        setConfirm({
            title: 'Do you really want to delete this IP?',
            content: (
                <>
                    <Typography>IP: {endpoint.ip}</Typography>
                    <Typography>Port: {endpoint.port}</Typography>
                    <Typography>Protocol: {endpoint.protocol.toUpperCase()}</Typography>
                    {endpoint.description ? <Typography>Description: {endpoint.description}</Typography> : null}
                </>
            ),
            confirmText: 'Delete',
            confirmColor: 'error',
            confirmVariant: 'text',
            cancelColor: 'primary',
            cancelVariant: 'text',
            onConfirm: () => {
                deleteEndpoint(endpoint);
                toggleShowConfirm();
            },
            onCancel: () => {
                toggleShowConfirm();
            },
        });
        toggleShowConfirm();
    };

    const deleteEndpoint = async endpoint => {
        deleteCustomerEndpoint.mutate(
            { endpointId: endpoint.id },
            {
                onSuccess: () => {
                    toast.success(
                        `IP address ${endpoint.ip}:${
                            endpoint.port
                        } (${endpoint.protocol.toUpperCase()}) successfully deleted.`,
                    );
                    refetch();
                },
                onError: error => {
                    const errorMessage = error?.response?.data?.message ?? 'Failed to delete IP address.';
                    toast.error(errorMessage);
                    refetch();
                },
            },
        );
    };

    const onEdit = endpoint => {
        setEndpointDialog({
            endpoint,
            onCancel: toggleShowEndpointDialog,
            onComplete: () => {
                toggleShowEndpointDialog();
                refetch();
            },
        });
        toggleShowEndpointDialog();
    };

    const onCreate = () => {
        setEndpointDialog({
            create: true,
            onCancel: toggleShowEndpointDialog,
            onComplete: () => {
                toggleShowEndpointDialog();
                refetch();
            },
        });
        toggleShowEndpointDialog();
    };

    const LoadingRow = () => (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography color="text.secondary" textAlign="center" fontStyle="italic">
                    - Loading -
                </Typography>
            </TableCell>
        </TableRow>
    );

    const EmptyRow = () => (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography color="text.secondary" textAlign="center" fontStyle="italic">
                    - The Customer Access Control List is empty -
                </Typography>
            </TableCell>
        </TableRow>
    );

    const ErrorRow = () => (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography color="text.error" textAlign="center" fontStyle="italic">
                    {error.message}
                </Typography>
            </TableCell>
        </TableRow>
    );

    return (
        <Box component="div">
            <Typography component="h4" variant="h4" sx={{ mb: 4 }}>
                Customer Access Control
            </Typography>
            <Typography component="h6" variant="h6" sx={{ mb: 2 }}>
                What IP addresses can make calls via this account?
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="Customer ACL Table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '1%' }}>IP</TableCell>
                            <TableCell sx={{ width: '1%' }}>Port</TableCell>
                            <TableCell sx={{ width: '1%' }}>Protocol</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right" sx={{ width: 114, pr: 4 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? (
                            <LoadingRow />
                        ) : error ? (
                            <ErrorRow />
                        ) : !endpoints.length ? (
                            <EmptyRow />
                        ) : (
                            endpoints.map(endpoint => {
                                return (
                                    <TableRow
                                        key={endpoint.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{}}>{endpoint.ip}</TableCell>
                                        <TableCell sx={{}}>{endpoint.port}</TableCell>
                                        <TableCell sx={{}}>{endpoint.protocol.toUpperCase()}</TableCell>
                                        <TableCell>{endpoint.description}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => {
                                                    onEdit(endpoint);
                                                }}
                                                aria-label="edit"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    askDelete(endpoint);
                                                }}
                                                aria-label="delete"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box component="div" sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={onCreate}>
                    Add
                </Button>
            </Box>
            {showConfirm ? (
                <Confirm
                    title={confirm.title}
                    cancelText={confirm.cancelText}
                    onCancel={confirm.onCancel}
                    confirmText={confirm.confirmText}
                    confirmColor={confirm.confirmColor}
                    confirmVariant={confirm.confirmVariant}
                    cancelColor={confirm.cancelColor}
                    cancelVariant={confirm.cancelVariant}
                    onConfirm={confirm.onConfirm}
                >
                    {confirm.content}
                </Confirm>
            ) : null}
            {showEndpointDialog ? <EditCustomerEndpointDialog {...endpointDialog} /> : null}
        </Box>
    );
}

export default AccessControlList;
