import { Box, Typography } from '@voiplabscom/design';
import { useState, useReducer } from 'react';
import { DataTable } from 'app/components/common/DataTable';
import { EditUserDialog } from 'app/components/users/EditUserDialog';
import { useUsersListQuery } from 'app/hooks/queries/users';
import { upperFirst } from 'lodash';
import { Confirm } from 'app/components/common/Confirm';
import { useDeleteUser } from 'app/hooks/mutations/users';
import { toast } from 'react-toastify';
import { useAuthSelector } from 'app/data/auth';
import { UserType } from 'types/users';

function UserList() {
    const [showConfirm, toggleShowConfirm] = useReducer(show => !show, false);
    const [confirm, setConfirm] = useState({});
    const [showUserDialog, toggleShowUserDialog] = useReducer(show => !show, false);
    const [userDialog, setUserDialog] = useState({});
    const deleteUserMutation = useDeleteUser();
    const authState = useAuthSelector();
    const isAdmin =
        authState.authTokenDecoded?.user_type === UserType.Admin ||
        authState.authTokenDecoded?.user_type === UserType.SuperAdmin;

    const { data: users, isLoading, error, refetch } = useUsersListQuery();

    const tableColumns = [
        { key: 'first_name', header: 'First Name', sx: { width: '1%', whiteSpace: 'nowrap' } },
        { key: 'last_name', header: 'Last Name', sx: { width: '1%', whiteSpace: 'nowrap' } },
        { key: 'email_address', header: 'E-mail address', sx: { width: '1%' } },
        { key: 'type', header: 'User Type', transform: upperFirst },
    ];

    const onDelete = user => {
        setConfirm({
            title: 'Do you really want to delete this User?',
            content: (
                <>
                    <Typography>
                        Name: {user.first_name} {user.last_name}
                    </Typography>
                    <Typography>E-mail address: {user.email_address}</Typography>
                    <Typography>Type: {upperFirst(user.type)}</Typography>
                </>
            ),
            confirmText: 'Delete',
            confirmColor: 'error',
            confirmVariant: 'text',
            cancelColor: 'primary',
            cancelVariant: 'text',
            onConfirm: () => {
                deleteUser(user);
                toggleShowConfirm();
            },
            onCancel: () => {
                toggleShowConfirm();
            },
        });
        toggleShowConfirm();
    };
    const onEdit = user => {
        setUserDialog({
            user,
            onCancel: toggleShowUserDialog,
            onComplete: () => {
                toggleShowUserDialog();
                refetch();
            },
        });
        toggleShowUserDialog();
    };
    const onAdd = () => {
        setUserDialog({
            create: true,
            onCancel: toggleShowUserDialog,
            onComplete: () => {
                toggleShowUserDialog();
                refetch();
            },
        });
        toggleShowUserDialog();
    };

    const deleteUser = async user => {
        deleteUserMutation.mutate(
            { userId: user.id },
            {
                onSuccess: () => {
                    toast.success(
                        `User ${user.first_name} ${user.last_name} (${user.email_address}) successfully deleted.`,
                    );
                    refetch();
                },
                onError: error => {
                    const errorMessage = error?.response?.data?.message ?? 'Failed to delete User.';
                    toast.error(errorMessage);
                    refetch();
                },
            },
        );
    };

    return (
        <Box component="div">
            <Typography component="h4" variant="h4" sx={{ mb: 4 }}>
                Users
            </Typography>
            <DataTable
                variant="outlined"
                rows={users}
                columns={tableColumns}
                onEdit={isAdmin ? onEdit : null}
                onDelete={isAdmin ? onDelete : null}
                onAdd={isAdmin ? onAdd : null}
                isLoading={isLoading}
                errorMessage={error?.message}
            />
            {showConfirm ? (
                <Confirm
                    title={confirm.title}
                    cancelText={confirm.cancelText}
                    onCancel={confirm.onCancel}
                    confirmText={confirm.confirmText}
                    confirmColor={confirm.confirmColor}
                    confirmVariant={confirm.confirmVariant}
                    cancelColor={confirm.cancelColor}
                    cancelVariant={confirm.cancelVariant}
                    onConfirm={confirm.onConfirm}
                >
                    {confirm.content}
                </Confirm>
            ) : null}
            {showUserDialog ? <EditUserDialog {...userDialog} /> : null}
        </Box>
    );
}

export default UserList;
