import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

interface ActiveCarrierDeleteData {
    carrierId: string;
    accountId?: string;
}

export const useDeleteActiveCarrier = () => {
    const { currentAccountId, authToken } = useAuthSelector();
    return useMutation((data: ActiveCarrierDeleteData) =>
        sdk.active_carriers.delete(data.accountId ?? currentAccountId, data.carrierId, {
            authToken: authToken,
        }),
    );
};
