import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

interface CustomerEndpointDeleteData {
    endpointId: string;
    accountId?: string;
}

export const useDeleteCustomerEndpoint = () => {
    const authState = useAuthSelector();
    return useMutation((data: CustomerEndpointDeleteData) =>
        sdk.customer_endpoints.delete(data.accountId ?? authState.currentAccountId, data.endpointId, {
            authToken: authState.authToken,
        }),
    );
};
