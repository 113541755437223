/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';

// import { GlobalStyle } from 'styles/global-styles';
import { CssBaseline, useMediaQuery, ThemeProvider, createTheme } from '@voiplabscom/design';
import { theme } from '../themes';

import { default as Routing } from './Routing';
import { AuthGate } from './components/AuthGate';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
// import {} from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useAuthSlice } from './data/auth';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parseGraphqlErrorCode } from './utilities';

// const queryClient = new QueryClient({
//   /* default options are only a fallback and will not be used if the
//      if the respective option is set explicitly in the query/mutation option
//   */
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: process.env.NODE_ENV === 'production',
//       staleTime: 20 * 1000, // set a minimum stale time on all queries to deduplicate requests?
//     },
//   },
// });

function App() {
    const { i18n } = useTranslation();
    const { actions: authActions } = useAuthSlice();
    const dispatch = useDispatch();

    const handleRetry = (failureCount, error) => {
        const maxRetries = 3;
        const { status: errorCode } = error.response;
        if (failureCount < maxRetries) {
            // Only retry on 5xx errors
            if (errorCode >= 500 && errorCode <= 599) {
                return true;
            }
        }

        return false;
    };

    const queryClient = useMemo(
        () =>
            new QueryClient({
                queryCache: new QueryCache({
                    onError: (error: any) => {
                        const { data: errorData, status: errorCode } = error.response;
                        switch (errorCode) {
                            case 401:
                                // unauthenticated request, cancel queries, clear cache and log user out
                                queryClient.cancelQueries();
                                queryClient.clear();
                                dispatch(authActions.logout(null));
                                toast.error(`Authentication Failed: ${errorData.message}`);
                                break;

                            default:
                                toast.error(`${errorCode} Error: ${errorData.message}`);
                                break;
                        }
                    },
                }),

                mutationCache: new MutationCache({
                    onError: (error: any) => {
                        const { data: errorData, status: errorCode } = error.response;
                        switch (errorCode) {
                            case 401:
                                // unauthenticated request, cancel queries, clear cache and log user out
                                queryClient.cancelQueries();
                                queryClient.clear();
                                dispatch(authActions.logout(null));
                                toast.error(`Authentication Failed: ${errorData.message}`);
                                break;

                            default:
                                toast.error(`${errorCode} Error: ${errorData.message}`);
                                break;
                        }
                    },
                    // onSuccess: data => {},
                }),

                /* default options are only a fallback and will not be used if the
       if the respective option is set explicitly in the query/mutation option
    */
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: process.env.NODE_ENV === 'production',
                        // staleTime: 20 * 1000, // set a minimum stale time on all queries to deduplicate requests?,
                        retry: handleRetry,
                    },
                },
            }),
        [dispatch, authActions],
    );

    // @ts-ignore
    const usedTheme = createTheme(theme);

    return (
        <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
                <QueryClientProvider client={queryClient}>
                    <Helmet
                        titleTemplate="%s :: TrunkingIO"
                        defaultTitle="TrunkingIO"
                        // htmlAttributes={{ lang: i18n.language }}
                    >
                        <meta name="description" content="TrunkingIO Browser App" />
                    </Helmet>
                    <ThemeProvider theme={usedTheme}>
                        <CssBaseline />
                        <AuthGate>
                            <Routing />
                        </AuthGate>
                    </ThemeProvider>
                    <ToastContainer position={'top-right'} />
                    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
                    {/* <GlobalStyle /> */}
                </QueryClientProvider>
            </QueryParamProvider>
        </BrowserRouter>
    );
}

export default App;
