import {
    Box,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@voiplabscom/design';
import { AddBox as AddBoxIcon, Delete as DeleteIcon, Edit as EditIcon } from '@voiplabscom/design/icons-material';

type DataTableProps = {
    sx: any;
    variant?: 'default' | 'outlined';
    rows: any[];
    rowIdKey?: string;
    columns: {
        key: string;
        header: string;
        sx?: {};
        transform?: (value: string) => void;
        custom?: (row: object) => void;
    }[];
    onEdit?: (row: {}) => void;
    onDelete?: (row: {}) => void;
    onAdd?: () => void;
    addButtonText?: string;
    addButtonType?: 'button' | 'link';
    isLoading?: boolean;
    errorMessage?: string;
};

function DataTable({
    sx = {},
    variant = 'default',
    rows,
    rowIdKey,
    columns,
    onEdit,
    onDelete,
    onAdd,
    addButtonText = 'Add',
    addButtonType = 'button',
    isLoading,
    errorMessage,
}: DataTableProps) {
    const hasActions = onEdit || onDelete ? true : false;
    const colCount = columns.length + (hasActions ? 1 : 0);
    const TableHeaders = () => (
        <TableHead>
            <TableRow>
                {columns.map(col => {
                    return (
                        <TableCell
                            key={col.key}
                            sx={{
                                ...col.sx,
                                textTransform: 'uppercase',
                                fontSize: '.7rem',
                                fontWeight: 'bold',
                                color: 'tertiary.light',
                            }}
                        >
                            {col.header}
                        </TableCell>
                    );
                })}
                {hasActions ? (
                    <TableCell
                        align="right"
                        sx={{
                            width: 114,
                            pr: 4,
                            textTransform: 'uppercase',
                            fontSize: '.7rem',
                            fontWeight: 'bold',
                            color: 'tertiary.light',
                        }}
                    >
                        Actions
                    </TableCell>
                ) : null}
            </TableRow>
        </TableHead>
    );

    const LoadingRow = () => (
        <TableRow>
            <TableCell colSpan={colCount}>
                <Typography color="text.secondary" fontStyle="italic">
                    - Loading -
                </Typography>
            </TableCell>
        </TableRow>
    );

    const EmptyRow = () => (
        <TableRow>
            <TableCell colSpan={colCount}>
                <Typography color="text.secondary" fontStyle="italic">
                    - The Customer List is empty -
                </Typography>
            </TableCell>
        </TableRow>
    );

    const ErrorRow = () => (
        <TableRow>
            <TableCell colSpan={colCount}>
                <Typography color="text.error" fontStyle="italic">
                    {errorMessage}
                </Typography>
            </TableCell>
        </TableRow>
    );

    return (
        <Box sx={sx}>
            <TableContainer component={variant === 'outlined' ? Paper : 'div'}>
                <Table sx={{ minWidth: 650 }} size={hasActions ? 'small' : 'medium'} aria-label="Table">
                    <TableHeaders />
                    <TableBody>
                        {isLoading ? (
                            <LoadingRow />
                        ) : errorMessage ? (
                            <ErrorRow />
                        ) : !rows.length ? (
                            <EmptyRow />
                        ) : (
                            rows.map(row => {
                                return (
                                    <TableRow
                                        key={rowIdKey ? row[rowIdKey] : row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {columns.map(col => {
                                            return (
                                                <TableCell key={col.key} sx={{ ...col.sx }}>
                                                    {col.custom
                                                        ? col.custom(row)
                                                        : col.transform
                                                        ? col.transform(row[col.key])
                                                        : row[col.key]}
                                                </TableCell>
                                            );
                                        })}
                                        {hasActions ? (
                                            <TableCell align="right">
                                                {onEdit ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            onEdit(row);
                                                        }}
                                                        aria-label="edit"
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                ) : null}
                                                {onDelete ? (
                                                    <IconButton
                                                        onClick={() => {
                                                            onDelete(row);
                                                        }}
                                                        aria-label="delete"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                ) : null}
                                            </TableCell>
                                        ) : null}
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {onAdd ? (
                addButtonType === 'button' ? (
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={onAdd}>
                            {addButtonText}
                        </Button>
                    </Box>
                ) : (
                    <Box>
                        <Button
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                margin: 'auto',
                                display: 'block',
                                minWidth: '200px',
                                borderRadius: '10px',
                            }}
                            onClick={onAdd}
                        >
                            {addButtonText}
                        </Button>
                    </Box>
                )
            ) : null}
        </Box>
    );
}

export default DataTable;
