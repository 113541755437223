/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware, StoreEnhancer, combineReducers } from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mask from 'json-mask';

import { pickBy } from 'lodash';
import { createReducer } from './reducers';

import { persistInitialState } from 'app/data/auth';

// import { reducer as authReducer } from '../app/data/auth';
// import { reducer as localReducer } from '../app/data/local';

// const rootReducer = combineReducers({
//   auth: authReducer,
//   local: localReducer,
// });

// const persistConfig = {
//   key: 'root',
//   version: 1,
//   storage,
//   whitelist: ['auth'],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const persistKeys = ['auth'];
// TODO: debounce like https://typeofnan.dev/debouncing-with-redux-middleware/
const persistMask = `
  auth(${Object.keys(persistInitialState).join(',')}),
`.replace(/\s/g, '');
// console.log('PERSISTMASK:', persistMask);
export const persistData = store => next => action => {
    // persist certain keys to localstorage
    // - should only be doing when those keys->data have changed...
    //   - can NOT do by checking "action.type" as that may be "logout" or something generic (ie we dont know if the type actually changed)
    // - expecting those keys to correlate to injected slices (ie "auth") that are read from localstorage
    // if (action.type === 'UPDATE_USER_DETAILS') {
    //   saveDebounce(store);
    // }
    // console.log('dataSaver:', action?.type);
    setTimeout(() => {
        const state = store.getState();
        const persistState = mask(state, persistMask);
        // const persistState = pickBy(state, (v, k) => persistKeys.includes(k));
        localStorage.setItem('app_persist_state', JSON.stringify(persistState || {}));
        console.log('Saved state to localStorage:', persistState);
    }, 1);
    return next(action);
};

const hydrateFromLocalStorage = () => {
    // console.log('hydrateFromLocalStorage');
    let lsData;
    let resultData;
    try {
        lsData = localStorage.getItem('app_persist_state');
        resultData = JSON.parse(lsData);
        // TODO: verify that it is of the same shape as the reducer map keys?
    } catch (err) {
        console.error('hydrate error:', err);
        console.log('error lsData:', lsData);
        resultData = undefined;
    }
    // console.log('resultData:', resultData);
    return resultData ?? undefined;
};

export const configureAppStore = (preloadedState?: any | undefined) => {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const { run: runSaga } = sagaMiddleware;

    // Create the store with saga middleware
    const middlewares = [sagaMiddleware, persistData];

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga,
        }),
    ] as StoreEnhancer[];

    // load store from localstorage
    preloadedState = preloadedState ?? hydrateFromLocalStorage();

    const store = configureStore({
        reducer: createReducer(), //persistedReducer, //createReducer(),
        // use concat/prepend to avoid loss of type info
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
                thunk: false,
            }).concat(middlewares),
        // middleware: [
        //   ...getDefaultMiddleware({
        //     serializableCheck: {
        //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        //     },
        //   }),
        //   ...middlewares,
        // ],
        preloadedState,
        devTools: process.env.NODE_ENV === 'development' ? true : false,
        enhancers,
    });

    // // Make reducers hot reloadable, see http://mxs.is/googmo
    // /* istanbul ignore next */
    // if (module.hot) {
    //   module.hot.accept('./reducers', () => {
    //     forceReducerReload(store);
    //   });
    // }

    return store;
};
