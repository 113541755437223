import { useAuthSlice } from 'app/data/auth';
import * as React from 'react';

import { AppBar, Grid, Toolbar, Typography, Button } from '@voiplabscom/design';
import { useDispatch } from 'react-redux';

function Header() {
    // const {
    //   data: userData,
    //   isLoading: userIsLoading,
    //   error: userError,
    // } = useAuthenticatedUserQuery();

    // auth error will trigger logout in global handling - App.tsx, only error case we should log out?
    const { actions } = useAuthSlice();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(actions.logout({}));
    };

    return (
        <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <Grid container>
                    <Grid item>
                        <Typography variant="h6" noWrap component="div">
                            TrunkingIO
                        </Typography>
                    </Grid>
                    <Grid item sx={{ flex: 1 }} />
                    <Grid item>
                        <Typography variant="body1" noWrap component="div">
                            {/*{userIsLoading*/}
                            {/*  ? 'Loading...'*/}
                            {/*  : userError*/}
                            {/*  ? // @ts-ignore*/}
                            {/*    'Unable to load user'*/}
                            {/*  : `${userData.doc.first_name} ${userData.doc.last_name}`}*/}
                            <Button variant={'contained'} onClick={handleLogout}>
                                Logout
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
