import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@voiplabscom/design';
import { Edit as EditIcon, Delete as DeleteIcon } from '@voiplabscom/design/icons-material';
import _ from 'lodash';
import { useCarriersListMasterQuery, useCarriersListQuery } from 'app/hooks/queries/carriers';
import { useActiveCarriersListQuery } from 'app/hooks/queries/active_carriers';
import { useCreateActiveCarrier, useDeleteActiveCarrier } from 'app/hooks/mutations/active_carriers';
import { toast } from 'react-toastify';
import { LoadingSwitch } from 'app/components/common/LoadingSwitch';
import { TruncatedTextTooltip } from 'app/components/common/TruncatedTextTooltip';
import { MainContentCard } from 'app/components/common/MainContentCard';

function CarriersList() {
    const carriers = useCarriersListQuery({ params: { include: 'gateways', paginate: false } });
    const masterCarriers = useCarriersListMasterQuery({ params: { include: 'destination', paginate: false } });
    const activeCarriers = useActiveCarriersListQuery({ params: { paginate: false } });
    const addActiveCarrier = useCreateActiveCarrier();
    const removeActiveCarrier = useDeleteActiveCarrier();

    const carriersList = _.map(_.sortBy(carriers.data, ['name']), carrier => {
        carrier.isActive = _.includes(activeCarriers.data, carrier.id);
        return carrier;
    });
    const masterCarriersList = _.map(_.sortBy(masterCarriers.data, ['name']), carrier => {
        carrier.isActive = _.includes(activeCarriers.data, carrier.id);
        return carrier;
    });

    const toggleActiveCarrier = carrier => {
        carrier.isChanging = true;
        const { isActive, id: carrierId } = carrier;
        const mutateActiveCarrier = isActive ? removeActiveCarrier : addActiveCarrier;
        mutateActiveCarrier.mutate(
            { carrierId },
            {
                onSuccess: () => {
                    carrier.isActive = !carrier.isActive;
                    if (isActive) {
                        _.pull(activeCarriers.data, carrierId);
                    } else {
                        activeCarriers.data.push(carrierId);
                    }
                    carrier.isChanging = false;
                },
                onError: error => {
                    const errorMessage =
                        error?.response?.data?.message ?? `Failed to ${isActive ? 'disable' : 'enable'} Carrier.`;
                    toast.error(errorMessage);
                    carrier.isChanging = false;
                },
            },
        );
    };

    const masterCarriersbyRegions = masterCarriersList.reduce((result, item) => {
        if (item.destination && item.destination.region_id) {
            let region = (result[item.destination.region_id] = result[item.destination.region_id] || []);
            let destination = _.find(region, { name: item.destination.name });
            if (destination) {
                destination.carriers.push(item);
                region.sort((a, b) => a.display_priority - b.display_priority);
            } else {
                const { destination } = item;
                region.push({
                    ...destination,
                    carriers: [item],
                });
            }
        }
        return result;
    }, {});

    console.log(carriers.data);

    const NoCarriers = () => {
        return (
            <Box sx={{ marginTop: '30px' }}>
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }} color="tertiary.light">
                    No Carrier Available
                </Typography>
            </Box>
        );
    };

    const CarriersByRegion = props => {
        const { data } = props;

        return (
            <>
                {data.map(destination => {
                    return (
                        <Box key={destination.id} sx={{ marginTop: '30px' }}>
                            <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }} color="tertiary.light">
                                {destination.name}
                            </Typography>
                            <Grid container spacing={0}>
                                {destination.carriers.map(carrier => {
                                    return (
                                        <Grid item xs={12} /* sm={6} md={12} xl={6} */ key={carrier.id}>
                                            <LoadingSwitch
                                                checked={carrier.isActive}
                                                loading={carrier.isChanging === true}
                                                onChange={() => toggleActiveCarrier(carrier)}
                                                inputProps={{
                                                    color: 'primary',
                                                    // size: 'small',
                                                }}
                                                sx={{ float: 'left' }}
                                            />
                                            <TruncatedTextTooltip title={carrier.name} placement="top" followCursor>
                                                <Typography
                                                    float="left"
                                                    component="div"
                                                    sx={{
                                                        lineHeight: '36px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {carrier.name}
                                                </Typography>
                                            </TruncatedTextTooltip>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    );
                })}
            </>
        );
    };

    const LoadingRow = () => (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography color="text.secondary" textAlign="center" fontStyle="italic">
                    - Loading -
                </Typography>
            </TableCell>
        </TableRow>
    );

    const EmptyRow = () => (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography color="text.secondary" textAlign="center" fontStyle="italic">
                    - The Customer Access Control List is empty -
                </Typography>
            </TableCell>
        </TableRow>
    );

    const ErrorRow = props => (
        <TableRow>
            <TableCell colSpan={5}>
                <Typography color="text.error" textAlign="center" fontStyle="italic">
                    {props.children}
                </Typography>
            </TableCell>
        </TableRow>
    );

    const byocIps = ['23.93.104.5', '100.55.22.33'];
    const byocIpsText = byocIps.length > 1 ? byocIps.slice(0, -1).join(', ') + ' and ' + byocIps.slice(-1) : byocIps[0];

    return (
        <Box component="div">
            <Grid
                container
                spacing={3}
                sx={{
                    '& h6': {
                        marginBottom: '10px',
                    },
                    '& .MuiPaper-rounded': {
                        borderRadius: '10px',
                    },
                }}
            >
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardContent>
                            <Typography component="h6" variant="h6">
                                TrunkFast℠ Carriers - North America
                            </Typography>
                            <Typography>Get going fast! The carriers below are ready now.</Typography>
                            {masterCarriersbyRegions.na ? (
                                <CarriersByRegion data={masterCarriersbyRegions.na} />
                            ) : (
                                <NoCarriers />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardContent>
                            <Typography component="h6" variant="h6">
                                TrunkFast℠ Carriers - Europe
                            </Typography>
                            <Typography>
                                If your customers are located in Europe, use these carriers for local
                                origination/termination.
                            </Typography>
                            {masterCarriersbyRegions.eu ? (
                                <CarriersByRegion data={masterCarriersbyRegions.eu} />
                            ) : (
                                <NoCarriers />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardContent>
                            <Typography component="h6" variant="h6">
                                TrunkFast℠ Peers
                            </Typography>
                            <Typography>
                                Make and receive calls directly from these peers via secure VoIP-only interconnects.
                                This also enables high definition audio and video, when available.
                            </Typography>
                            {masterCarriersbyRegions.voip ? (
                                <CarriersByRegion data={masterCarriersbyRegions.voip} />
                            ) : (
                                <NoCarriers />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <MainContentCard
                title="Bring Your Own Carrier"
                sx={{
                    marginTop: '40px',
                }}
            >
                <Typography component="h6" variant="h6">
                    Add your own carriers to the mix. Your dedicated IPs for all traffic are {byocIpsText}
                </Typography>
                <Typography sx={{ fontSize: '1rem' }}>
                    Please note: You are responsible for all configuration and debugging when you bring your own
                    carriers.
                </Typography>
                <Table sx={{ minWidth: 650, marginTop: '20px' }} size="small" aria-label="Customer ACL Table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '1%' }}>Enabled</TableCell>
                            <TableCell sx={{ width: '1%' }}>Carrier</TableCell>
                            <TableCell sx={{ width: '1%' }}>Carrier IPs</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell align="right" sx={{ width: 114, pr: 4 }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {carriersList.isLoading || activeCarriers.isLoading ? (
                            <LoadingRow />
                        ) : carriersList.error ? (
                            <ErrorRow>{carriersList.error}</ErrorRow>
                        ) : activeCarriers.error ? (
                            <ErrorRow>{activeCarriers.error}</ErrorRow>
                        ) : !carriersList.length ? (
                            <EmptyRow />
                        ) : (
                            carriersList.map(carrier => {
                                return (
                                    <TableRow
                                        key={carrier.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <LoadingSwitch
                                                checked={carrier.isActive}
                                                loading={carrier.isChanging === true}
                                                onChange={() => toggleActiveCarrier(carrier)}
                                                inputProps={{
                                                    color: 'primary',
                                                    // size: 'small',
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{carrier.name}</TableCell>
                                        <TableCell>
                                            {carrier.gateways
                                                ? carrier.gateways.map(gateway => {
                                                      return <Typography>{gateway.gateway}</Typography>;
                                                  })
                                                : null}
                                        </TableCell>
                                        <TableCell>{carrier.description}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() => {
                                                    // onEdit(carrier);
                                                }}
                                                aria-label="edit"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    // askDelete(carrier);
                                                }}
                                                aria-label="delete"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
                <Box>
                    <Button
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            margin: 'auto',
                            display: 'block',
                            minWidth: '200px',
                            borderRadius: '10px',
                        }}
                    >
                        Add Another Carrier
                    </Button>
                </Box>
            </MainContentCard>
        </Box>
    );
}

export default CarriersList;
