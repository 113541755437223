import { Tooltip } from '@voiplabscom/design';
import { useState } from 'react';

const TruncatedTextTooltip = props => {
    const [open, setOpen] = useState(false);
    return (
        <Tooltip
            {...props}
            open={open}
            onOpen={props => {
                //@ts-ignore
                const isTruncated = props.target.offsetWidth < props.target.scrollWidth;
                if (isTruncated) {
                    setOpen(true);
                }
            }}
            onClose={() => {
                setOpen(false);
            }}
        ></Tooltip>
    );
};
export default TruncatedTextTooltip;
