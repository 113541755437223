import { useMutation } from 'react-query';
import { useAuthSelector } from 'app/data/auth';
import { sdk } from 'app/sdk';

interface UserDeleteData {
    userId: string;
    accountId?: string;
}

export const useDeleteUser = () => {
    const authState = useAuthSelector();
    return useMutation((data: UserDeleteData) =>
        sdk.users.delete(data.accountId ?? authState.currentAccountId, data.userId, {
            authToken: authState.authToken,
        }),
    );
};
